<template>
    <div class="container-fluid" id="lessonsList" style="min-height: 900px">
        <div class="row">
            <div class="col-12 col-md-9 order-2 order-md-1">
                <div id="main" class="col-md-9">
                    <Paste
                        v-on:generate="parseText($event.text)"
                    />
                </div>
                <div id="side" class="col-md-3">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import Paste from './Paste.vue'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C

export default {
    name: 'Parser',
    components: {
        Paste,
    },
    methods: {
        parseText(text) {
            const data = {
                items: text
            }
            UseAPI('/new/parse-array', { method: "POST", body: JSON.stringify(data), api: "corpus" })
            .then((result) => {
                console.log('result', result)
            })
        }
    }
}
</script>
