<template>
    <div class="row">
        <textarea id="paste_area" class="w-100" v-model="text" style="min-height:350px" placeholder="Insert text to parse">
        </textarea>
        <button class="btn bg-primary marg-big" @click="generate()">Parse</button>
    </div>
</template>

<script>
export default {
    name: "Paste",
    data() {
        return {
            text: "女の子がすき。\n大好きですけど。。。ちょっと困る。"
        }
    },
    methods: {
        generate() {
            const text = this.text.split('\n')
            this.$emit('generate', { text })
        }
    }
}
</script>
